@import "../../scss/Variables.scss";

.custom-select {
    position: relative;
    width: max-content;
    isolation: isolate;
    @include default-box-shadow;
    .custom-select__native-select {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 1;
        &:focus + .custom-select__visual-select {
            outline: 1px dotted #212121;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: 2px;
        }
        &:hover{
            cursor: pointer;
        }
    }
    .custom-select__visual-select {
        background-color: white;
        border-radius: 10px;
        padding: 8px 16px;
        label{
            color: $color-primary;
            background-color: white;
            font-size: 0.9em;
        }
        .custom-select__visual-select__wrapper {
            display: flex;
            align-items: center;
            width: max-content;
            position: relative;
            overflow: hidden;
            pointer-events: none;
            z-index: 1;
            .option{
                margin-right: 4px;
            }
            .visual-select__drop-icon{
                width: fit-content;
                pointer-events: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                color: $color-primary;
            }
        }
    }
}
