@import "../../../../../../scss/Variables.scss";

.registered-progression{
    margin: 16px 0 0 0;
    &.center{
        display: flex;
        justify-content: center;
    }
    .container{
        &:first-child{
            display: flex;
            align-items: center;
            margin: 0 0 16px 0;
            &:before{
                margin: 0 8px 0 0;
                @include person;
            }
            .label{
                font-weight: 500;
            }
        }
        &:nth-child(2){
            .info{
                display: flex;
                align-items: center;
                margin: 0 0 8px 0;
                gap: 8px;
                
                .label{
                    span{
                        &:first-child{
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}