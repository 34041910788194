.ds-btn {
    width: auto;
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    border: 2px solid;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    // Alignment
    &.ds-btn--centered {
        justify-content: center;
    }
    &.ds-btn--left .ds-icon {
        margin-right: 4px;
    }
    &.ds-btn--right {
        .ds-icon {
            margin-left: 4px;
            order: 2;
        }

        .ds-btn__label {
            order: 1;
        }
    }

    // Size
    &.ds-btn--size-large {
        font-size: 1.5em;
        gap: 14px;
    }

    // Variants & colors
    .ds-icon svg {
        fill: currentColor;
    }
    &.ds-btn--primary {
        color: var(--white);
        background-color: var(--primary-blue);
        border-color: var(--primary-blue);

        &:not(:disabled):hover {
            background-color: var(--white);
            color: var(--primary-blue);
        }

        &.ds-btn--color-primary {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
            &:not(:disabled):hover {
                color: var(--color-primary);
            }
        }
        &.ds-btn--color-secondary {
            background-color: var(--color-secondary);
            border-color: var(--color-secondary);

            &:not(:disabled):hover {
                color: var(--color-secondary);
            }
        }
    }
    &.ds-btn--secondary,
    &.ds-btn--secondary-light {
        color: var(--primary-blue);
        border-color: currentColor;
        &:not(:disabled):hover {
            background-color: var(--primary-blue);
            color: var(--white);
            border-color: var(--primary-blue);
        }

        &.ds-btn--color-primary {
            color: var(--color-primary);
            &:not(:disabled):hover {
                background-color: var(--color-primary);
                border-color: var(--color-primary);
            }
        }
        &.ds-btn--color-secondary {
            color: var(--color-secondary);
            &:not(:disabled):hover {
                background-color: var(--color-secondary);
                border-color: var(--color-secondary);
            }
        }
    }
    &.ds-btn--secondary-light {
        border-color: var(--medium-grey);

        &.ds-btn--color-primary {
            border-color: var(--color-primary-light);
        }
    }
    &.ds-btn--tertiary {
        border-color: transparent;
        color: var(--primary-blue);
        &:not(:disabled):hover {
            background-color: var(--layout-lightblue);
        }

        &.ds-btn--color-primary {
            color: var(--color-primary);
            &:not(:disabled):hover {
                background-color: var(--color-primary-light);
            }
        }
        &.ds-btn--color-secondary {
            color: var(--color-secondary);
            &:not(:disabled):hover {
                background-color: transparent;
            }
        }
    }
}
