@import "../../../../scss/Variables.scss";

.classroom-list-container{
    > .header{
        display: flex;
        align-items: center;
        margin: 16px 0 24px 0;
        h1{
            margin: 0;
        }
        .btn{
            width: fit-content;
            margin-left: auto;
        }
    }
    .tabs-container{
        display: flex;
        margin: 24px 0 0 0;
        flex-wrap: wrap;
        [data-reach-tabs]{
            width: 300px;
            margin: 0 16px 16px 0;
            [data-reach-tab-panel]{
                height: 395px;
                > span{
                    display: block;
                    margin: 0 0 8px 0;
                }
            }
        }
        .classroomInfos{
            margin: 0 0 16px 0;
            flex-wrap: wrap;
            .code{
                width: 100%;
                margin: 0 8px 8px 0;
                text-align: center;
                span{
                    &:nth-child(2){
                        font-weight: 700;
                    }
                }
            }
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                color: $color-dashboard-primary;
                padding: 16px 0;
                background-color: $background-color-secondary;
                color: $color-dashboard-primary;
                border-radius: 10px;
                span{
                    &:first-child{
                        font-size: 16px;
                    }
                    &:nth-child(2){
                        text-decoration: underline;
                        padding: 0 0 0 4px;
                        font-size: 1em;
                    }
                    &:nth-child(3){
                        font-size: 16px;
                    }
                }       
            }
        }
        .no-data{
            padding: 16px;
            background-color: $background-color-secondary;
            border-radius: 10px;
        }
    }
}