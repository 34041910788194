@import '../../scss/Variables.scss';

.breadcrumbs {
    ul{
        display: flex;
        li{
            display: flex;
            &:not(.current){
                a{ 
                    color: $color-primary;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .breadcrumb__separator{
                    padding: 0 8px;
                    user-select: none;
                }
            }
            &:last-child{
                .breadcrumb__separator{
                    display: none;
                }
            }
        }
    }
}
