.student-path__button .student-path__icon {
    width: 100%;
    height: auto;
}

.student-path__image {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
}

.student-path__modal-title {
    display: flex;
    justify-content: center;
    align-items: center;

    > *:not(:last-child) {
        margin-right: 8px;
    }
}
