@import "../../../../../../scss/Variables.scss";

.create-classroom-modal{
    padding: 32px;
    position: relative;
    .register{
        
        .addName-classroom{
            label, input{
                display: block;
            }
            label{
                margin: 0 0 4px 0;
                color: $color-undefined;
                font-size: 1em;
            }
            input{
                width: 100%;
                height: 40px;
                padding: 8px;
                font-size: 1.7em;
                border: none;
                border-bottom: 1px solid $color-dashboard-primary;
                background: none;
            }
        }
        .actions{
            display: flex;
            justify-content: flex-end;
            margin: 32px 0 0 0;
            button{
                width: fit-content;
                margin: 0 0 0 32px;
                &.cancel{
                    color: rgba($color: #000000, $alpha: 0.5);
                    transition: all 0.2s ease-out;
                    &:hover{
                        color: $color-font-default;
                    }
                }
            }
        }
    }
    .saving{
        p{
            font-weight: 700;
            text-align: center;
        }
        .evb-spinner__container{
            margin: 0 auto;
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0, 0);
        }
    }
    .saved{
        .success{
            p:first-child{
                span{
                    color: $color-correct;
                    margin: 0 4px 0 0;
                }
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 16px 0;
                font-weight: 700;
            }
            .message{
                span{
                    display:block;
                    margin: 0 0 16px 0;
                    text-align: center;
                }
                .label{
                    margin: 0 0 8px 0;
                }
                .code{
                    font-size: 1.7em;
                }
            }
        }
        button{
            width: fit-content;
            margin: 32px auto 0 auto;
        }
    }
}