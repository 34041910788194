.single-classroom-container
    > .container
    .modules-container
    [data-reach-tabs].single-classroom__module-card
    [data-reach-tab-list]
    .tablist__tab.--with-toggle {
    display: flex;
    justify-content: space-between;

    > *:last-child {
        margin-right: 16px;
    }
}
