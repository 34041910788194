$button-txt-color: #006CCB;

.module-map {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
        margin-right: 12px;
    }

    > img {
        // For optical alignment
        position: relative;
        top: 3px;
    }

    .module-map__button-title {
        color: $button-txt-color;
        text-decoration: underline;
    }
}

.module-map__modal-title {
    display: flex;
    align-items: center;
    justify-content: center;
    > *:not(:last-child) {
        margin-right: 12px;
    }
}
