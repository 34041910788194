@import "../../scss/Variables.scss";

.modal-container {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    border-radius: 10px;
    padding: 0;
    left: 50%;
    top: calc(66px + 45%); /* take header height into account */
    transform: translate(-50%, -50%);
    position: absolute;
    margin: 0;
    overflow: hidden;
    width: auto;

    .modal {
        max-height: calc(90vh - 66px); /* Substract header height */
        max-width: 90vw;
        width: auto;
        display: flex;
        flex-direction: column;

        header {
            padding: 16px;
            border-bottom: 1px solid lightgrey;
            position: relative;
            display: flex;
            align-items: center;

            .modal__title {
                display: block;
                width: 90%;
                margin: 0 auto;
                text-align: center;
                font-size: 1.1em;
                font-weight: 500;
            }
            
            > button {
                margin-left: auto;
                position: absolute;
                right: 32px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .modal__content-container {
            overflow: scroll;
        }

        &.no-title {
            .actions {
                display: flex;
                justify-content: flex-end;
                .close-button {
                    padding: 8px;
                }
            }
        }
    }
}
