@import "../../../../scss/Variables.scss";

$heightObjective: 80px;
$marginTopCurrentObjective: 20px;
$heightActivity: 50px;

.chatbot-student-drawer {
    // override Drawer default styles
    top: 86px !important;
    width: 0% !important;
    z-index: 9 !important;
    height: calc(100% - 86px) !important;

    .title {
        color: $text-color-primary;
        font: 32px Roboto;
        margin-top: 15px;
        font-weight: 300;
    }

    .sub-title {
        margin-bottom: 8px;
        font: normal normal bold 16px/21px Roboto;
        color: $text-color-primary !important;
    }
    .description {
        font: normal normal normal 14px/19px Roboto;
        color: $text-color-primary !important;
    }

    .drawer-content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        background: none;
    }

    .drawer-handle {
        box-shadow: 6px 0 5px -2px rgba(0, 0, 0, 0.15) !important; 
        border-radius: 0 !important;
        top: 0px;
        height: 100%;
        width: 42px;
        align-items: start;
        
        .custom-drawer-handle-icon {
            position: relative;
            top: 42px;
            left: 22px;
            color: white;
            background-color: $color-primary;
            border-radius: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 43px;
            padding: 1px;
            transition: transform .2s;
            &.opened {
                transform: rotate(180deg);
            }
        }
    }

    .journey-objectives-container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 32px;

        &:before {
            content: "";
            width: 2px;
            height: calc(100% - 60px);
            position: absolute;
            left: 10px;
            top: 0;
            background-color: $color-primary;
            margin: 30px 0;
        }

        .objective {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: $heightObjective;
            &.current-objective {
                align-items: flex-start;
                min-height: $heightObjective - $marginTopCurrentObjective;
                margin-top: $marginTopCurrentObjective;
                z-index: 2;
                border: 2px solid $color-primary;
                border-radius: 10px;
                background-color: $background-color-secondary;
                position: relative;
                padding: 0 16px 0 40px;
                .rc-collapse{
                    .rc-collapse-item{
                        .rc-collapse-header {
                            outline: none;
                            padding: 0;
                            position: absolute;
                            bottom: -8px;
                        }
                        .rc-collapse-content{
                            margin: 16px 0 32px 0;
                            padding: 0;
                            overflow: initial;
                           
                        }
                    }
                }
            }

            .objective-bubble-container {
                position: relative;

                .objective-bubble {
                    min-width: 21px;
                    height: 21px;
                    background-color: $color-primary;
                    border-radius: 100px;
                    margin-right: 12px;
                    position: relative;
                    z-index: 2;

                    .blazon {
                        position: absolute;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                        top: -3px;
                        left: 7px;
                    }
                    .lock {
                        width: 13px;
                        position: absolute;
                        left: 4px;
                        top: 2px;
                    }

                    &.locked {
                        border: 2px solid $color-primary-light;
                        background-color: white;
                        right: 2px;
                    }
                }

                .objective-handle {
                    z-index: 0;
                    position: absolute;
                    height: $heightObjective + 28px;
                    left: 9px;
                    border: 1px solid $color-primary;
                    &.downward {
                        &.short-downhandle {
                            height: $heightObjective;
                        }
                    }
                    &.upward {
                        bottom: 0px;
                    }
                    &.dark {
                        border: 1px solid $color-primary;
                    }
                }
            }

            .current-objective-tear {
                width: 54px;
                position: absolute;
                left: -18px;
                top: 8px;
            }

            .objective-description {
                margin: 8px 0 16px 0;
                &.future-objective {
                    color: rgba(0, 0, 0, 0.5);
                    font-weight: lighter;
                }
            }
        }

        .rc-collapse {
            border: none;
            background: none;
            .rc-collapse-content {
                background: none;
                .rc-collapse-content-box{
                    margin: 0;
                }
                .journey-activities {
                    display: flex;
                    flex-direction: column;
                    overflow: initial;
                }
            }
        }

        .expand-icon-current-objective {
            display:flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px 10px 10px 20px;
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
            padding: 2px 4px 2px 20px;
            background-color: $color-primary ;
            .material-icons {
                font-size: 20px;
                color: white;
                margin: 0px 3px 0px -20px;
                position: absolute;
                z-index: 2;
            }
            .details-text {
                font-size: 11px;
                color: white;
                line-height: 16px;
            }
        }
    }
}

