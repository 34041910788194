[data-reach-dialog-content].layer-modal {
    width: auto;
    max-width: 80vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: 0;
    padding: 0;
    background-color: var(--layout-darkblue);
    border-radius: 30px;
    overflow: hidden;
    
    .layer-modal__inner {
        max-height: calc(100vh - 48px);
        overflow-y: scroll;
        
        .layer-modal__backdrop {
            color: var(--white);
            padding: 32px;
        }

        .layer-modal__main {
            background-color: var(--white);
            border-radius: 30px 30px 0px 0px;
            padding: 32px;
        }
    }
}

[data-reach-dialog-overlay] {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--grey-25);
}

@media screen and (max-width: 768px) {
    [data-reach-dialog-content].layer-modal {
        max-width: 90vw;
    }
}
