@import "../../../../../../scss/Variables.scss";

.students-summary{
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    padding: 16px;
    background-color: white;
    border-radius: 10px;
    @include default-box-shadow;
    > div{
        margin: 0 8px 0 0;
        padding: 0 8px 0 0;
        border-right:1px solid rgba($color: #000000, $alpha: 0.3);
        &:last-child{
            margin: 0;
            padding: 0;
            border-right: none;
        }
        span{
            display: block;
            &.number{
                display: flex;
                align-items: center;
                &:before{
                    margin: 0 4px 0 0;
                }
            }
            &.label{
                padding: 8px 0 0 0;
                font-size: 0.9em;
                color: rgba($color: #000000, $alpha: 0.6);
            }
        }
    }
    .total{
        span{
            &.number{
                &:before{
                    @include person;
                }
            }
        }
    }
    .has-improved{
        span{
            &.number{
                &:before{
                    @include progression("improvement")
                }
            }
        }
    }
    .has-difficulties{
        span{
            &.number{
                &:before{
                    @include progression("regression")
                }
            }
        }
    }
}