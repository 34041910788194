@import "../../../../scss/Variables.scss";

.teacher-player-info-panel {
    top: 68px;
    width: 0% !important;
    z-index: 9 !important;

    .title {
        background-color: rgb(233, 249, 252);
        color: $text-color-primary !important;
        font: normal normal bold 16px/21px Roboto;
    }

    .sub-title {
        margin-bottom: 8px;
        font: normal normal bold 16px/21px Roboto;
        color: $text-color-primary !important;
    }
    .description {
        font: normal normal normal 14px/19px Roboto;
        color: $text-color-primary !important;
    }

    .panel-section {
        background-color: $background-color-gray;
        border: none !important;
        margin-bottom: 10px;
        margin-left: 24px;
        border-radius: 6px;
        padding: 20px;

        .rc-collapse-content-box {
            margin-top: 0 22px;
        }
    }


    .rc-collapse {
        border: none !important;
        background-color: $background-color-primary;

        .rc-collapse-item {
            border: none !important;
            color: $text-color-primary;
            background-color: $background-color-primary;
            margin-top: 20px;

            .rc-collapse-header {
                background-color: $background-color-primary;

                .custom-arrow {
                    margin-right: 8px;
                }
            }
        }
    }

    .drawer-content {
        background: linear-gradient(
            180deg,
            $background-color-gray 47px,
            $background-color-primary 00%
        );
        padding-top: 47px;
    }

    // override drawer-icon
    .drawer-handle {
        top: 0px;
        height: 100%;
        width: 42px;
        background: linear-gradient(
            180deg,
            $background-color-gray 47px,
            $background-color-primary 00%
        );
        align-items: start;

        .custom-drawer-handle-icon {
            position: relative;
            top: 18px;
        }
    }

}
