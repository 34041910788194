$list-item-border: #a8a8a8;
$activated-bg-color: #f2f6fb;
$activated-border-color: #006CCB;
$inactive-color: rgba(112, 118, 131, 1);

$dependency-inner-border-color: rgba(255, 255, 255, 0.32);

$deactivate-dependency-bg-color: #FFF7F5;
$deactivate-dependency-border-color: #FFA68F;
$deactivate-highlight-dependency-bg-color: #D6370E;
$deactivate-dependency-toggle-bg: #FF6138;
$deactivate-dependency-toggle-pin: #FFB6A3;
$deactivate-dependency-dot-color: #FF6138;

$activate-highlight-dependency-bg-color: #1E8449;
$activate-dependency-dot-color: #006CCB;

.manager-list-item {
    border: 1px solid $list-item-border;
    display: flex;
    min-height: 68px;
    align-items: stretch;
    transition: all 0.5s ease-in-out, border-width 0.2s linear;

    &.list-item--clickable:hover {
        border-width: 2px;

        &.--locked, &.--descendent {
            border-color: $activated-border-color;
        }

        &.--descendent {
            border-style: solid;
        }
    }

    > * {
        padding: 9px 14px;
        display: flex;
        align-items: center;
    }

    > *:first-child {
        flex: 1;
    }

    > *:not(:first-child) {
        border-left: 1px solid $list-item-border;
    }

    &.--unlocked {
        background-color: $activated-bg-color;
        border-color: $activated-border-color;
    }

    &.--locked {
        color: $inactive-color;
    }
    
    &.--deactivateDependency {
        background-color: $deactivate-dependency-bg-color;
        border-color: $deactivate-dependency-border-color;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            background-color: $deactivate-dependency-dot-color;
        }

        > *:not(:last-child) {
            border-right: 1px solid $dependency-inner-border-color;
        }   

        .toggle {
            background-color: $deactivate-dependency-toggle-bg;

            .toggle__pin .material-icons {
                color: $deactivate-dependency-toggle-pin;
            }
        }

        .list-item__child--highlighted {
            background-color: $deactivate-highlight-dependency-bg-color;
            color: white;

            &.--bold {
                font-weight: 700;
            }
        }
    }

    &.--activateDependency {
        position: relative;
        background-color: $activated-bg-color;
        border-color: $activated-border-color;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            background-color: $activate-dependency-dot-color;
        }

        > *:not(:last-child) {
            border-right: 1px solid $dependency-inner-border-color;
        }   

        .list-item__child--highlighted {
            background-color: $activate-highlight-dependency-bg-color;
            color: white;

            &.--bold {
                font-weight: 700;
            }
        }
    }
}
