@import "../../../../scss/Variables.scss";     

$cellHeight: 55;

.progression-table {
    .table-legend {
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        padding: 16px;
        margin: 0 0 16px 0;
        gap: 16px;

        li {
            display: flex;
            align-items: center;
            gap: 8px;

            &.box:before {
                content: "";
                width: 24px;
                height: 16px;
                border: 1px solid var(--table-border-color);
                border-radius: 5px;
            }
            &.validated:before {
                background-color: var(--objective-completed-color);
                border-color: var(--table-border-color);
            }
            &.in-progress:before {
                background-color: var(--objective-in-progress-color);
            }
            &.objective-not-started:before {
                background-color: white;
            }
        }
    }

    .table-content {
        display: flex;
        isolation: isolate;
        
        .column {
            &.left {
                max-width: 200px;
                min-width: 200px;
                position: relative;
                z-index: 2;
                isolation: isolate;
                .heading {
                    height: $cellHeight + px;
                    position: relative;
                    border: 1px solid var(--table-border-color);
                    border-bottom: none;
                    box-sizing: border-box;
                    span {
                        position: absolute;
                        &:first-child {
                            left: 16px;
                            bottom: 8px;
                        }
                        &:nth-child(2) {
                            right: 16px;
                            top: 8px;
                        }
                        &:last-child {
                            width: 205px;
                            height: 1px;
                            background-color: var(--table-border-color);
                            transform-origin: center;
                            transform: translate(-50%, -50%) rotate(195deg);
                            left: 50%;
                            top: 50%;
                        }
                    }
                }
                ul {
                    isolation: isolate;
                    li {
                        height: $cellHeight + px;
                        &:hover{}
                        a{
                            position: relative;
                            z-index: 1;
                            display: flex;
                            height: 100%;
                            .inside-container{
                                display: flex;
                                align-items: center;
                                position: relative;
                                z-index: 3;
                                padding: 0 8px;
                                > .icon{
                                    &.in-difficulty{
                                        height: 24px;
                                        &:before{
                                            @include progression('regression');
                                        }
                                    }
                                    &.none:before{
                                        @include progression('none');
                                    }
                                }
                                .name-container{
                                    display: flex;
                                    align-items: center;
                                    padding: 0 0 0 8px;
                                    flex-wrap: wrap;
                                    > span{
                                        width: 100%;
                                    }
                                    .name{
                                        line-height: 16px;
                                    }
                                    .label{
                                        display: none;
                                        align-items: center;
                                        font-size: 0.8em;
                                        color: $color-dashboard-primary;
                                        
                                        .icon{
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                            .scalable-background{
                                display: block;
                                width: 100%;
                                height: 100%;
                                background-color: white;
                                border: 1px solid var(--table-border-color);
                                box-sizing: border-box;
                                border-bottom: none;
                                background-color: white;
                                position: absolute;
                                z-index: 2;
                                left: 0;
                                top: 0;
                                transform: scale(1);
                                transition: all 0.2s ease-out;
                            }
                            &:hover{
                                z-index: 4;
                                .inside-container{
                                    > .icon{
                                        position: relative;
                                        margin: 0 0 8px 0;
                                        &.in-difficulty{}
                                        &.none{}
                                    }
                                    .name-container{
                                        .label{
                                            display: flex;
                                        }
                                    }
                                }
                                .scalable-background{
                                    height: 100%;
                                    box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.35);
                                    border-bottom: 1px solid var(--table-border-color);
                                    border-radius: 10px;
                                    transform: scale(1.1);

                                }
                            }
                        }
                        &:last-child {
                            border-bottom: 1px solid var(--table-border-color);
                        }
                    }
                }
            }
            &.right {
                position: relative;
                z-index: 1;
                width: 100%;
                [data-simplebar]{
                    width: 100%;
                }
                .table-objectives{
                    .tr{
                        display: flex;
                        box-sizing: border-box;

                        .objective-progression {
                            transform: scale(0.98);
                        }

                        .th, .td{
                            width: 100%;
                            min-width: 60px;
                            box-sizing: border-box;
                            button{
                                display: block;
                                height: 100%;
                            }
                        }
                        .th{
                            height: $cellHeight + 1 + px;
                            background-color: white;
                            font-size: 1em;
                            border-right: 1px solid var(--table-border-color);
                            border-top: 1px solid var(--table-border-color);
                            border-bottom: 1px solid var(--table-border-color);
                        }
                    }
                }
            }
        }
    }
}
