@import "../../../../../../scss/Variables.scss";
[data-reach-menu-popover]{
    z-index: 20;
}
.settings{
    [data-reach-menu-button]{
        display: flex;
        align-items: center;
        .icon{
            padding: 0 4px 0 0;
            font-size: 24px;
            color: $color-dashboard-primary;
        }
    }
}

.settings-options{
    padding: 0;
    border-color: $color-dashboard-primary-light;
    @include default-box-shadow;
    [data-reach-menu-item]{
        display: flex;
        align-items: center;
        padding: 16px;
        .icon{
            padding: 0 4px 0 0;
            font-size: 16px;
            color: $color-dashboard-primary;
        }
        &[data-selected]{
            background-color: $color-dashboard-primary;
            .icon{
                color: white;
            }
        }
    }
}