@import "../../../../../scss/Variables.scss";

$orange: #ff6138;

.ProgressionGraph {
    > h2{
        display: flex;
        align-items: center;
        font-size: 1.7em;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        > div{
            margin-left: 8px;
        }
    }
    > p{
        padding-top: 12px;
        padding-bottom: 32px;
        font-size: 1.1em;
        font-weight: 400;
    }
    .legend {
        display: flex;
        .col {
            margin-left: 32px;
            > div {
                display: flex;
                align-items: center;
                margin: 0 0 10px 0;
                .color {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > span{
                        width: 16px;
                        height: 16px;
                        border: 1px solid;
                        border-radius: 2px;
                        box-sizing: border-box;
                    }
                }
                .expected > span{
                    border-color: var(--alert-conf-default);
                    background-color:var(--alert-conf-bubble);
                }
                .success-level > span{
                    border-width: 2px;
                    border-color: var(--alert-conf-chip-dark);
                    background-color: var(--white);
                }
                > span{
                    &:first-child{
                        margin-right: 8px;
                    }
                    &:last-child{
                        color:var(--black)
                    }
                }
            }
        }
    }

    > .container {
        width: 100%;
        padding: 0 32px 0 0;
        position: relative;
        isolation: isolate;
        .axis{
            position: absolute;
            width: calc(100% - 32px);
            height: 232px;
            margin: 0 0 0 0px;
            left: 0;
            bottom: 117px;
            > div{
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--medium-grey);
            }
            .axis__x{
                bottom: 0;
                width: 100%;
                height: 1px;
                top: auto;
            }
            .axis__y{
                height: 100%;
                width: 1px;
                span{
                    position: absolute;
                    top: -25px;
                    left: -5px;
                    font-size: 0.8rem;
                    color: var(--grey);
                }
                &:after{
                    position: absolute;
                    top: -7px;
                    left: -3px;
                    content: '';        
                    display : inline-block;
                    height : 0;
                    width : 0;
                    border-right : 4px solid transparent;
                    border-bottom : 7px solid var(--medium-grey);
                    border-left : 4px solid transparent;     
                }
            }
        }
        .graphic {
            display: flex;
            height: 300px;
            padding-top: 32px;
            .activity {
                height: calc(100% - 21px);
                padding: 0 16px;
                > .container {
                    display: flex;
                    height: 100%;
                    .exercise {
                        display: flex;
                        flex-direction: column;
                        .bars {
                            height: 100%;
                            display: flex;
                            align-items: flex-end;
                            margin: 0 16px;
                            border-bottom: 3px solid var(--alert-conf-default);
                            box-sizing: border-box;
                            position: relative;
                            > button{top:0}
                            .expected, .current {
                                width: 34px;
                                margin: 0 4px;
                                border-radius: 5px 5px 0 0;
                                display: flex;
                                justify-content: center;
                                > span{
                                    margin-top: 12px;
                                }
                            }
                            .expected {
                                background-color: var(--alert-conf-bubble);
                                margin-left: 0;
                                border: 1px solid var(--alert-conf-default);
                                border-bottom: none;
                                box-sizing: border-box;
                            }
                            .current {
                                border: 2.5px solid var(--alert-conf-chip-dark);
                                border-bottom: none;
                                box-sizing: border-box;
                                background-color: var(--alert-conf-default);
                                margin-right: 0;
                                overflow: hidden; //To prevent the icon to be pushed out of the container
                                &.positive {
                                    background-color: var(--alert-sup-default);
                                }
                                &.negative {
                                    background-color: var(--alert-inf-default);
                                }

                                a {
                                    display: block;
                                    height: 100%;
                                    .material-icons,
                                    .icon-improvement {
                                        position: absolute;
                                        top: -16px;
                                        left: 50%;
                                        margin-left: -12px;
                                        background-color: white;
                                        border-radius: 100%;
                                    }
                                    .icon-improvement {
                                        width: 24px;
                                        height: 24px;
                                        background-color: $color-correct;
                                        border: 2px solid white;
                                        box-sizing: border-box;
                                    }
                                    .material-icons {
                                        color: $orange;
                                    }
                                }
                            }
                            .break-progression {
                                cursor: pointer;
                            }
                        }
                        .title {
                            padding: 10px 0;
                            margin-top: 9px;
                            text-align: center;
                            background-color: var(--alert-conf-bubble);
                            text-decoration: underline;
                        }
                        .title:hover {
                            font-weight: bold;
                        }
                        &:first-child .bars {
                            margin-left: 0;
                        }
                        &:last-child .bars {
                            margin-right: 0;
                        }
                        &:first-child{
                            .title{
                                border-radius: 5px 0 0 5px;
                            }
                        }
                        &:last-child{
                            .title{
                                border-radius: 0 5px 5px 0;
                            }
                        }
                        &:only-child{
                            .title{
                                border-radius: 5px;
                            }
                        }
                    }
                }
                > .title {
                    margin: 16px 0 0 0;
                    text-align: center;
                    font-weight: 500;
                }
            }
        }
    }

    .simplebar-track{
        bottom: 0;
    }
    .simplebar-track.simplebar-horizontal,
    .simplebar-track.simplebar-horizontal .simplebar-scrollbar{
        height: 13px;
    }
}