/* Design systen colors */
:root{
    --primary-blue: #006CCB;
    --dark-primary-blue: #0062B9;
    --light-primary-blue: #4694D9;
    --lightblue: #ADCCF5;

    --black: #192A3E;
    --grey: #707683;
    --grey-25: rgba(121, 118, 131, 0.25);
    --medium-grey: #C2CFE0;
    --light-grey: #F3F5F9;
    --white: #fff;
    --white-15: rgba(255, 255, 255, 0.15);
    
    --layout-darkblue: #00274A;
    --layout-greyblue: #334D6E;
    --layout-lightblue: #F4F7FE;
    --layout-containerincard: #F8FAFC;
    --layout-grey: #FAFAFE;
    
    --alert-inf-default: #FFA726;
    --alert-inf-hover: #D98A17;
    --alert-inf-layoutbubble: #FAE7CC;
    --alert-inf-chip-dark: #4C4D3F;
    --alert-inf-chip-light: #fff7eb;

    --alert-sup-default: #26A4AB;
    --alert-sup-hover: #3D8A8F;
    --alert-sup-bubble: #CFEBED;
    --alert-sup-chip-dark: #205F74;
    --alert-sup-chip-light: #ebf8f9;

    --alert-conf-default: #8AC0EC;
    --alert-conf-hover: #7AADD6;
    --alert-conf-bubble: #E5F1FB;
    --alert-conf-chip-dark: #305D83;
    --alert-conf-chip-light: #E8F2FB;

    --left-plot-filled: #ECF5FC;

    --false: #C81D1D;
    --true: #186A3A;
    --level: #FFB03B;

    --purple-dark: #5046B9;
    --purple-medium: #C4D3F9;
    --purple-light: #EAF0FD;

    --font-black: #192A3E;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
