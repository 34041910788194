@import "../../../../scss/Variables.scss";
$separator-color: #736abd;
$description-text-color: rgba(0, 0, 0, 0.56);
$prm-button-color: #006ccb;

.single-classroom {
    .breadcrumbs {
        margin-left: 8px;
    }
    .single-classroom-container {
        > .name {
            margin: 0 0 32px 8px;
        }

        .prm-access-button {
            background: #ffffff;
            border: 1px solid $prm-button-color;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            color: $prm-button-color;
            text-transform: uppercase;
            padding: 12px;
            margin-left: 8px;
            margin-bottom: 24px;
            font-size: 0.875rem;

            > *:not(:last-child) {
                margin-right: 12px;
            }
        }

        > .container {
            display: flex;
            width: 100%;
            .modules-container {
                &.full-width {
                    width: 100%;
                }
                display: flex;
                flex-direction: column;

                .active-modules,
                .inactive-modules {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                }

                .separator {
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 32px 0 16px 0;
                    isolation: isolate;

                    .separator__strike-through {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        height: 2px;
                        background-color: $separator-color;
                        z-index: -1;
                    }

                    .separator__title {
                        background: #736abd;
                        border-radius: 10px;
                        padding: 12px;
                        color: white;
                    }

                    .separator__icon {
                        width: 68px;
                        margin: 0 30px;
                    }
                }

                .no-active-modules__title {
                    font-size: 2.25rem;
                    text-align: center;
                    color: $description-text-color;
                    padding: 0 32px;
                    margin-bottom: 32px;
                }

                .inactive-modules__description {
                    color: $description-text-color;
                    text-align: center;
                    margin-bottom: 32px;
                    padding: 0 32px;
                }

                .no-active-modules__title + .inactive-modules__description {
                    margin-bottom: 64px;
                }

                [data-reach-tabs] {
                    max-width: 300px;
                    width: 100%;
                    margin: 8px;
                    [data-reach-tab-list] {
                        [data-reach-tab] {
                            cursor: default;
                            width: 80%;
                            justify-content: start;
                        }
                        .settings {
                            transform: translateX(-8px);
                            .settings__menu-button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 55px;
                                padding: 0 16px;
                                background-color: $background-color-secondary;
                                border: 1px solid $color-dashboard-primary-light;
                                border-radius: 0 10px 0 0;
                                border-left: none;
                                border-bottom: none;
                            }
                        }
                    }
                    [data-reach-tab-panels] {
                        [data-reach-tab-panel] {
                            height: 280px;
                        }
                    }
                }
                .no-progression {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 65px 0;
                    font-size: 48px;
                    color: rgba($color: #000000, $alpha: 0.2);
                }
            }
            .students-in-difficulty {
            }
        }
    }
}
