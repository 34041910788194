.player-modal-container {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    border-radius: 29px;
    padding: 0;
    left: 50%;
    top: calc(66px + 45%); /* take header height into account */
    transform: translate(-50%, -50%);
    position: absolute;
    margin: 0;
    overflow: hidden;
    .modal {
        max-height: calc(90vh - 66px); /* Substract header height */
        max-width: 90vw;
        width: auto;
        display: flex;
        flex-direction: column;

        header {
            min-height: 90px;
            padding: 16px 32px 16px 32px;
            border-bottom: 1px solid lightgrey;
            position: relative;
            display: flex;
            align-items: center;
            .modal__title {
                display: block;
                width: 90%;
                margin: 0 auto;
                text-align: center;
                font-size: 2.25rem;
                font-family: "Roboto-Medium", sans-serif;
            }
        }

        .close-button {
            margin-left: auto;
            position: absolute;
            right: 32px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}