$grey-title-font: #4E4E4E;
$grey-description-font: rgba(0, 0, 0, 0.56);
$aside-bg-color: #ebf5f8;
$title-divider: rgba(0, 0, 0, 0.32);
$bg: white;

.pedagogical-resources-management {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    height: 100%;
    display: grid;
    grid-template-columns: 250px 1fr;
    overflow: hidden;

    > aside {
        position: relative;
        height: 100%;

        > div {
            height: 100vh;
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $aside-bg-color;
            padding: 24px 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .sidebar__backlink {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 0.875rem;
                color: $grey-title-font;
                font-weight: 700;
            }
        }
    }

    > main {
        background-color: $bg;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .prm__content-container {
            width: 100%;
            max-width: 1064px;
            flex: 1;
            display: flex;
            flex-direction: column;

            > h1 {
                font-size: 2.25rem;
                color: $grey-title-font;
                font-family: "Roboto", "sans-serif";
                margin-bottom: 24px;
                margin-top: 80px;
                padding: 0 32px;

                > span:not(:last-child) {
                    border-right: 1px solid $title-divider;
                    padding-right: 30px;
                    margin-right: 30px;
                }
            }

            .prm__description {
                font-size: 0.875rem;
                color: $grey-description-font;
                line-height: 1.7rem;
                padding: 0 32px;
            }

            .prm__manager-container {
                flex: 1 1 0;
                width: 100%;
                margin-top: 64px;
            }
        }
    }
}
