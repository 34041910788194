@import "../../../../../../scss/Variables.scss";

.classroom-name{
    display: flex;
    align-items: center;
    height: 50px;
    .idle{
        display: flex;
        align-items: center;
        .settings{
            margin: 0 0 0 24px;
        }
    }
    .edit{
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 8px;
        background-color: white;
        border-radius: 10px;
        @include default-box-shadow;
        input{
            margin: 0 8px 0 0;
            padding: 8px;
            border: none;
            border-radius: 10px;
        }
        > button{
            &.validate{
                color: $color-correct;
            }
            &.cancel{
                color: $color-error;
            }
        }
    }
    .saving{
        display: flex;
        align-items: center;
        padding: 0 16px;
        position: relative;
        background-color: white;
        border-radius: 10px;
        @include default-box-shadow;
        font-weight: 500;
        .evb-spinner__container{
            width: 50px;
            height: 50px;
            margin: 0 0 0 16px;
            position: relative;
            top: auto;
            right: auto;
            left: auto;
            transform: scale(0.5);
        }
    }
}