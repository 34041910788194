.student-progression {
    min-width: 900px;

    .student-progression__header-container {
        display: flex;
        gap: 24px;

        .student-identity__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 60px;
            height: 60px;
            position: relative;
            isolation: isolate;
            .ds-icon{
                position: relative;
                z-index: 2;
            }
            &:after{
                content:'';
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--layout-greyblue);
                width: 60px;
                height: 60px;
                border-radius: 100%;
                z-index: 1;
            }
        }

        .header__student-identity {
            width: 100%;
            h1 {
                font-family: Roboto;
                font-size: 1.5 rem;
                line-height: 1;
                font-weight: 700;
                margin-bottom: 16px;
                width: max-content;
            }

            p {
                font-size: 1rem;
            }
        }

        .header__info-labels {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 12px;
            flex: 1;
            width: 100%;
        }

        .header__close-button {
            align-self: flex-start;
            color: var(--white);

            .material-icons {
                font-size: 40px;
            }
        }
    }
}
