@import "../../../../scss/Variables.scss";

.notification-message--with-icon {
    .material-icons {
        margin-right: 8px;
    }

    &.--success .material-icons {
        color: $color-correct;
    }

    &.--failure .material-icons {
        color: $color-error;
    }
}
