@import "../../../../scss/Variables.scss";

.classrooms-navigation {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin: 0 0 8px 0;
    .custom-select{
        height: 100%;
        margin: 0 24px 0 0;
    }
    .btn{
        width: fit-content;
        margin-left: auto;
    }

    .selected-module__wrapper .lock-status-label {
        margin-left: 8px;
    }
}