// COLORS
$color-primary: var(--color-primary);
$color-primary-light: var(--color-primary-light);
$color-secondary: var(--color-secondary);
$color-correct: var(--color-correct);
$color-error: var(--color-incorrect);
$color-editor-one: var(--color-editor-one);
$color-editor-two: var(--color-editor-two);

$progress-background: #EAF4FD;
$progress-bar: #DBECFC;
$inactive-title: #828282;
$inactive-bar: #E5E5E5;
$success-bar: #FFF1E2;
$success-title: #CE800C;

$background-color-primary: #fff;
$background-color-secondary: #f2f6fb;
// 236 240 241
$background-color-gray: #ecf0f1;

$text-color-primary: #2c3e50;
$color-neutral-gray: #EEEEEE;
$color-undefined: #979797;

//Dashboard colors
$color-dashboard-primary: #8AC0EC;
$color-dashboard-primary-light: #CCE3F7;
$color-dashboard-interaction: #3885EB;

// FONTS
@mixin font-size($size) {
    font-size: $size;
}
$color-font-default: #444;

// OTHER
@mixin border-radius {
    border-radius: 10px;
}

@mixin width-height($size) {
    width: $size + px;
    height: $size + px;
}

@mixin adaptive-width($maxWidth) {
    width: 100%;
    max-width: $maxWidth + px;
}

@mixin material-icons {
    direction: ltr;
    display: inline-block;
    font-family: "Material Icons";
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

@mixin progression ($type){
    border-radius: 100%;
    @if $type == "improvement"{
        @include material-icons;
        content: "arrow_right_alt";
        background-color: #EBF9E5;
        color: $color-correct;
        transform: rotate(-45deg);
    }
    @if $type == "regression"{
        @include material-icons;
        content: "arrow_right_alt";
        background-color: #FFE5E5;
        color: $color-error;
        transform: rotate(45deg);
    }
    @if $type == "none"{
        display: flex;
        align-items: center;
        justify-content: center;
        content: "-";
        width: 24px;
        height: 24px;
        background-color: $background-color-secondary;
        color: $color-primary;
        font-size: 24px
    }
}

@mixin person {
    @include material-icons;
    content: "person";
    border-radius: 100%;
    background-color: $background-color-secondary;
    color: $color-dashboard-primary;
    font-size: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0);
}

@mixin default-box-shadow{
    box-shadow: 2px 12px 50px rgba($color: #000000, $alpha: 0.05);
}

/*
    font-size cheat sheet

    14px = 1em
    16px = 1.1em
    24px = 1.7em
    32px = 2.2em
*/