$toggle-bg: #c3cbcd;
$toggle-pin-color: white;
$selected-bg-color:  #006CCB;
$icon-deactivated-color: rgba(195, 203, 205, 0.67);
$icon-activated-color: rgba(56, 133, 235, 0.58);

.toggle {
    width: 40px;
    min-width: 40px;
    height: 20px;
    border-radius: 100px;
    background-color: $toggle-bg;
    padding: 2px;

    .toggle__pin {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: $toggle-pin-color;
        position: relative;
        transition: left 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        .material-icons {
            font-size: 12px;
        }
    }

    &.--selected {
        background-color: $selected-bg-color;

        .toggle__pin {
            left: 20px;

            .material-icons {
                color: $icon-activated-color;
            }
        }
    }
    &.--unselected .toggle__pin {
        left: 0;

        .material-icons {
            color: $icon-deactivated-color;
        }
    }
}
