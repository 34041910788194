.emotional-report__popup {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    max-width: 960px;
    width: 100%;
    margin: 32px;
    position: absolute;
    bottom: 100px;
    right: 150px;
    background-color: var(--speech-bubble-color);
    border-radius: 30px;

    h3 {
        border-radius: 30px 30px 0 0;
        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        font-size: 1.5rem;
        margin-bottom: 50px;
        padding: 20px;
        text-align: center;
    }

    .popup__content {
        display: flex;
        justify-content: center;

        ul {
            padding: 32px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: max-content max-content max-content;
            row-gap: 80px;
            column-gap: 100px;

            .popup__emotion-neutral {
                grid-column-start: 2;
                grid-row-start: 2;
            }

            button {
                background-color: var(--speech-bubble-color);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 50%;
                width: 150px;
                height: 150px;
                position: relative;

                svg {
                    width: 130px;
                    position: absolute;
                    bottom: 30%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                p {
                    position: absolute;
                    top: 70%;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 16px;
                }

                &.popup__button--clicked {
                    border: 1px solid var(--highlight-color);
                    color: var(--highlight-color);
                    font-weight: 700;
                }
            }
        }
    }

    /* Reference for the speech bubble https://codepen.io/quatmo/pen/jVoXQe */
    &:after {
        content: "";
        border-radius: 0 0 0 100%;
        box-shadow: 23px 0 0 0 var(--speech-bubble-color) inset;
        position: absolute;
        bottom: -25px;
        right: 10px;
        height: 25px;
        width: 50px;
    }

    /* Bot */
    &:before {
        content: url("../question-bot.svg");
        position: fixed;
        bottom: 32px;
        right: 32px;
        width: 132px;
    }

    .popup__send-button {
        background-color: var(--send-button-bg-color);
        color: var(--send-button-txt-color);
        padding: 20px 40px;
        border-radius: 100px;
        position: absolute;
        bottom: 32px;
        right: 32px;
        font-size: 1.125rem;
        font-weight: 700;
        display: flex;
        align-items: center;

        span {
            margin-left: 20px;
        }
    }
}

[data-reach-dialog-overlay] {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 100;
}

@media (max-width: 1150px) {
    .emotional-report__popup {
        left: 32px;
        max-width: revert;
        width: revert;
        right: 32px;
        bottom: 50%;
        transform: translateY(50%);

        /* Speech bubble */
        &:after {
            bottom: 0;
            right: 40px;
            width: 0;
            height: 0;
            border: 30px solid transparent;
            border-top-color: var(--speech-bubble-color);
            border-bottom: 0;
            margin-left: -30px;
            margin-bottom: -30px;
            border-radius: 0;
        }

        /* Bot */
        &:before {
            position: absolute;
            bottom: -160px;
            right: 5px;
        }

        .popup__content ul {
            gap: 15px;
        }
    }
}

@media screen and (max-width: 768px) {
    .emotional-report__popup {
        h3 {
            margin-bottom: 0;
        }

        .popup__content {
            padding: 32px;
            padding-bottom: 80px;

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                button {
                    width: 30vw;
                    height: 30vw;
                    min-width: min-content;
                    min-height: min-content;
                    max-width: 150px;
                    max-height: 150px;

                    svg {
                        width: 25vw;
                        min-width: 20px;
                        max-width: 130px;
                    }
                }
            }
        }
    }
}
