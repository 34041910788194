.error-popup{
    background-color: white; padding: 32px; border-radius: 10px;
    > div{
        &:first-child{
            margin: 0 0 24px 0; font-size: 1.7em; font-weight: 600; 
        } 
        &:nth-child(2){
            font-size: 1em;

        }
    }
    .alert-buttons{
        display: flex; justify-content: flex-end;  margin: 24px 0 0 0;
        button{
            width: max-content; margin: 0 0 0 16px;
        }
    }
}