@import "../../../scss/Variables.scss";

.error-message{
    display: flex; align-items: center; background-color: rgba($color: red, $alpha: 0.1); border-radius: 10px;
    > div{
        &:first-child{
            width: 100%;  padding: 16px; border-right: 1px solid rgba($color: $color-undefined, $alpha: 0.5);
        } 
    }
    .alert-buttons{
        display: flex; justify-content: flex-end;
        button{
            width: max-content;  padding: 8px 16px; 
            &:first-child{
                border-right: 1px solid rgba($color: $color-undefined, $alpha: 0.5); color: $color-primary; font-weight: 600;
            }
            &.close-button{
                font-size: 32px; color: $color-undefined;
            }
        }
    }
}