@import "../../../../../../scss/Variables.scss";

.students-overview{
    max-width: 300px;
    h2{
        margin: 0 0 16px 0;
        font-size: 1.1em;
        font-family: "Roboto", sans-serif;
    }
    
    .students-in-difficulty{
        align-self: start;
        background-color: white;
        border-radius: 10px;
        @include default-box-shadow;
        header{
            display: flex;
            align-items: center;
            padding: 16px;
            margin: 0 0 8px 0;
            font-weight: 500;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            span{

                &:first-child{
                    font-size: 1.2em;
                }
            &:last-child{
                text-align: right;
                    margin: 0 0 0 16px;
                    flex-grow: 2;
            }
            }
        }
        .table{
            padding: 0 0 16px 0;
            .tr{
                display: flex;
                padding: 0 8px;
                &:first-child{
                    padding: 0 16px;
                }
                .th, a{
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    &.type, .type{
                        width: 45px;
                    }
                    &.name, .name, &.total-progression, .total-progression{
                        width: 130px;
                    }
                }
                .th{
                    margin: 0 0 8px 0;
                    color: rgba($color: #000000, $alpha: 0.5);
                    &.total-progression{
                        margin-left: auto;
                    }
                }
                a{
                    padding: 8px;
                    transition: all 0.2s ease-out;
                    border-radius: 10px;
                    border: 1px solid transparent;
                    overflow: hidden;
                    .td{
                        &.type{
                            &:before{
                                @include progression("regression")
                            }
                        }
                        &.name{
                        }
                        &.total-progression{
                            display: flex;
                            align-items: center;
                            justify-content: right;
                            position: relative;
                            .percent{
                                display: flex;
                                align-items: center;
                            }
                            .link-label{
                                display: flex;
                                align-items: center;
                                position: absolute;
                                transform: translate(150px, 0);
                                transition: all 0.1s ease-out;
                                font-size: 0.8em;
                                color: $color-dashboard-primary;
                            }
                            .icon{
                                color: rgba($color: #000000, $alpha: 0.25);
                            }
                        }
                    }
                    &:hover{
                        border-color: $color-dashboard-primary-light;
                        background-color: $background-color-secondary;
                        box-shadow: 2px 2px 8px rgba($color: #000000, $alpha: 0.25);
                        .td{
                            &.total-progression{
                                .percent{
                                    display: none;
                                }
                                .link-label{
                                    position: relative;
                                    transform: translate(0, 0);
                                    .icon{
                                        color: $color-dashboard-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .no-students{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(0, 0, 0, 0.5);
            }
        }

    }

    //TEMP
    h2, 
    .students-summary,
    .students-in-difficulty header span:first-child,
    .students-in-difficulty .table .tr:first-child,
    .students-in-difficulty .table .tr a .type,
    .students-in-difficulty .table .tr a .td.total-progression .percent{
        display: none;
    }
    .students-in-difficulty header span:last-child{
        margin: 0;
        text-align: left;
    }
    .students-in-difficulty .table .tr a .name{
        span{
            display: flex;
            align-items: center;
            &:before{
                @include person;
                color: $color-error;
                margin: 0 8px 0 0;
            }
        }
    }

}