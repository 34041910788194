.bm-error-modal {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    padding: 60px 50px 30px 50px;

    h2 {
        font-family: "Roboto";
        font-size: 2.25rem;
        margin-bottom: 30px;
    }

    > p {
        font-size: 1.5rem;
        margin-bottom: 70px;
    }

    .bm-error__primary-btn,
    .bm-error__secondary-btn {
        padding: 15px 25px;
        display: block;
        float: right;
        text-transform: uppercase;
        border-radius: 100px;
        font-size: 0.875rem;
        font-weight: 700;
    }
    
    .bm-error__primary-btn {
        color: white;
        background-color: #126BE0;
    }

    .bm-error__secondary-btn {
        color: #4E4E4E;
    }
}

@media (max-width: 768px) {
    .bm-error-modal {
        font-size: 1.5rem;
        font-weight: 700;
    }
}