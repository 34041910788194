.ds-chip {
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 1rem;
    padding: 8px 12px;
    width: max-content;
    display: flex;
    gap: 12px;
    align-items: center;
    color: var(--font-black);
    background-color: var(--light-grey);
    line-height: 1;

    .ds-chip__set-type-icon {
      margin-left: -2px;
      margin-right: -2px;
    }

    .ds-chip__label {
      margin-right: 10px;
    }

    &.ds-chip--conf {
      background-color: var(--alert-conf-chip-light);
    }
    &.ds-chip--inf {
      background-color: var(--alert-inf-chip-light);
    }
    &.ds-chip--sup {
      background-color: var(--alert-sup-chip-light);
    }

    &.ds-chip--dark-bg {
      color: var(--white);
      background-color: var(--white-15);
  
      &.ds-chip--conf {
        background-color: var(--alert-conf-chip-dark);
      }
      &.ds-chip--inf {
        background-color: var(--alert-inf-chip-dark);
      }
      &.ds-chip--sup {
        background-color: var(--alert-sup-chip-dark);
      }
    }
}
