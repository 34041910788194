.ds-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        height: inherit;
        width: inherit;
        display: flex;
        align-items: center;
    }

    &.ds-icon--small {
        width: 16px;
        height: 16px;
    }
    &.ds-icon--medium {
        width: 24px;
        height: 24px;
    }
    &.ds-icon--large {
        width: 32px;
        height: 32px;
    }
    &.ds-icon--clickable {
        width: 48px;
        height: 48px;
    }
}
