@import "../../../../../scss/App.scss";

.wizard-feedback-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .feedback_correctness {
        flex: 0 0 auto;
        position: relative;
        padding: 30px;
        color: #fff;

        .icon-container {
            @include width-height(50);
            position: absolute;
            left: 24px;
            bottom: -20px;
            border-radius: 100%;
            border: none;

            span {
                font-size: 50px;
            }
        }

        &.--incorrect {
            background-color: $color-error;

            .icon-container {
                background-color: $color-error;
            }
        }

        &.--correct {
            background-color: $color-correct;

            .icon-container {
                background-color: $color-correct;
            }
        }
    }

    > p {
        padding: 32px 16px 16px 16px;

        span {
            font-weight: 500;

            &.correct {
                color: $color-correct;
            }

            &.incorrect {
                color: $color-error;
            }
        }
    }

    .feedback__content {
        flex: 0 1 auto;
        height: 100%;
        overflow: auto;

        .feedback__explanation {
            padding: 0 16px;
            line-height: 1.4em;
        }

        .feedback__image {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}

@media screen and (min-width: 1280px) {
    .wizard-feedback-display {
        .feedback__content {
            max-height: 545px;
        }
    }
}

@media screen and (max-width: 1279px) {
    .wizard-feedback-display {
        .feedback__content {
            max-height: 380px;
        }
    }
}

@media screen and (max-width: 768px) {
    .wizard-feedback-display {
        .feedback__content {
            max-height: 200px;
        }
    }
}
