@import "../../../scss/Variables.scss";

[data-reach-tabs]{
    background: none;
    [data-reach-tab-list]{
        background: none;
        align-items: flex-end;
        button.tablist__tab{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 45px;
            padding: 0;
            border: none;
            background: white;
            border-radius: 10px 10px 0 0; 
            transition: all 0.2s ease-out;
            color: $color-undefined;
            border: 1px solid $color-dashboard-primary-light;
            border-bottom:none;
            &[data-selected], &:hover{
                height: 55px;
                font-size: 1.1em;
                color: $color-font-default;
                span{
                    font-weight: 500;
                }
            }
            &:first-child{
                width: 60%;
            }
            &:nth-child(2){
                margin:0 5% 0 auto;
            }
            span{
                padding: 0 16px;
                text-align: left;
            }
        }
    }
    [data-reach-tab-panels]{
        background-color: white;
        border-radius: 0 10px 10px 10px;
        border: 1px solid $color-dashboard-primary-light;
        border-top:none;
        @include default-box-shadow;
        height: calc(100% - (35px + 16px));
        [data-reach-tab-panel]{
            padding: 16px;
        }
    }
}