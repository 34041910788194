.player-header--empty {
    .player-header__breadcrumbs {
        display: flex;
        gap: 20px;

        li {
            background-color: white;
            border-radius: 10px;
            padding: 8px 16px;
        }
    }

    .player-header__leave-link {
        color: white;
    }
}
