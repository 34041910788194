@import "../../scss/Variables.scss";
$cellHeight: 55;

.objective-progression {
    width: 100%;
    min-width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    .objective-title {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    
    .objective-title__lock-label-placeholder {
        height: 16px;
        margin: 4px 0 8px 0;
    }

    .objective-progression__button {
        height: $cellHeight + px;
        background-color: white;
        display: block;
        isolation: isolate;
        border: 1px solid var(--table-border-color);
        border-radius: 5px;
        position: relative;

        &.--dashed {
            border: 2px dashed var(--table-border-color);
            border-radius: 8px;
        }

        .alert {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .icon {
            opacity: 0;
            position: absolute;
            left: 4px;
            bottom: 4px;
            font-size: 16px;
            color: white;
            transition: all 0.2s ease-out;
        }
    }

    &:hover {
        &:not(.--none) .objective-progression__button {
            border-color: $color-dashboard-interaction;
            border-width: 2px;
        }

        .icon {
            opacity: 1;
        }
    }

    &.none .objective-progression__button {
        background-color: white;

        .icon {
            display: none;
        }
    }
    &.--inProgress .objective-progression__button {
        background-color: var(--objective-in-progress-color);
    }
    &.--completed .objective-progression__button {
        border-style: solid;
        background-color: var(--objective-completed-color);
    }
}
